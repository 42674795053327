import AthenaAuthorize from '../../security/ui/AthenaAuthorize'
import { ExitToApp } from '@material-ui/icons'
import React from 'react'

function LinkBynder(props: {bynderLinkMetaPropertyValue?: string, bynderLinkMetaProperty?: string}) {
  const bynderUrl = `https://marcom.henselphelps.com/media/?resetsearch=&burlie&field=&filterType=add&field=text&value=${props.bynderLinkMetaPropertyValue}`;

  return (
      <AthenaAuthorize allowedRoles={'contributor'}>
        <div dangerouslySetInnerHTML={{ __html: `<a href=${bynderUrl}
           target={'_blank'} rel="noopener noreferrer"><ExitToApp style={{color: '#999'}}/>
          <img style={{height: '22px'}} src="https://www.bynder.com/static/img/svg/bynder-logo-blue.svg" alt="bynder logo"/>
        </a>` }} />
      </AthenaAuthorize>
  );
}

export default LinkBynder
